<template>
	<div>
		<web-header></web-header>
		<div class="main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
		</div>
		<div class="content main" v-html="richContent" style="margin-bottom:30px;"></div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页"],
				richContent: null,
			}
		},
		created() {
			this.richContent = sessionStorage.getItem('richText')
			this.menuList.push(sessionStorage.getItem('menuName'))
		},
	}
</script>

<style lang="less" scoped>
	.main {
		width: 1200px;
		margin: auto;
	}
</style>
